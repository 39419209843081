import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Form from '@components/Shared/Form/Form';
import TextField from '@components/Shared/TextField';
import Button from '@components/Shared/Buttons/Button';
import Colors from 'pubweb-smokey/dist/colors.js';
import LoadingIndicator from '@components/Shared/LoadingIndicator/LoadingIndicator';
import CheckmarkSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/checkmark.svg';
import { convertButtonColor, reverseColors } from '@utils/ctaUtils';
import { extractColors } from '@utils/utils';
import { DXAccountContext } from '@contexts/DXAccountContext';
import { runCommonValidation } from 'pubweb-smokey/dist/utils/validation';
import { submitForm } from '@services/formService';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';
import { pushGtmFormAction } from '@utils/gtmUtils';
import styles from './WebForm.module.css';

const WebForm = ({ webFormData }) => {
  const accountContext = useContext(DXAccountContext);
  const [formErrors, setFormErrors] = useState({});
  const [submittingForm, setSubmittingForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleValidation = (formState) => {
    if (submittingForm || formSubmitted) {
      return;
    }

    const validationErrors = runCommonValidation(formState);
    setFormErrors(validationErrors);
    return validationErrors.hasErrors;
  };

  const handleSubmit = (formData) => {
    if (submittingForm || formSubmitted) {
      return;
    }

    setSubmittingForm(true);

    const formSubmission = {
      ...formData,
      canEmail: true,
      originWebFormType: webFormData.originWebFormType,
      originWebApp: webFormData.originWebApp,
      originWebPageType: webFormData.originWebPageType,
      originCampaign: webFormData.originCampaign,
      originWebFormCategory: webFormData.originWebFormCategory,
      claytonAccountId: accountContext.state.account?.accountId || null,
      dataExtension: webFormData.dataExtension,
    };

    submitForm(formSubmission)
      .then(() => {
        pushGTMEvent('mrc-web-form'); // Per Digital Marketing, also keep this old UA event for now. It should be removed at a later time after GA4 event evaluation.
        pushGtmFormAction({
          form_action_type: webFormData.formActionType,
          form_name: webFormData.formName,
          model_number: webFormData.modelNumber,
          hc_number: webFormData.hcNumber,
          form_method: webFormData.formMethod,
          form_id: webFormData.formID,
          form_submit_text: webFormData.firstFormButtonText
            .trim()
            .toLowerCase()
            .replaceAll(' ', '_'),
        });

        if (typeof window !== 'undefined') {
          window.analytics.identify(accountContext.state?.account?.userId, {
            firstName:
              accountContext.state.account?.firstName || formData.firstName,
            lastName:
              accountContext.state.account?.lastName || formData.lastName,
            phone: accountContext.state.account?.phone || formData.phone,
            email: accountContext.state?.email || formData.email,
          });
        }

        setSubmittingForm(false);
        setFormSubmitted(true);
      })
      .catch(() => {
        setSubmittingForm(false);
        setFormSubmitted(false);

        setFormErrors({
          email: 'An error occurred, please try again.',
        });
      });
  };

  const buttonColors = extractColors(webFormData.buttonAndTextColors);

  return (
    <div className={styles.webform}>
      <div className={styles.webformText}>
        <h5>{webFormData.formHeader}</h5>
        <span className={styles.caption}>{webFormData.formIntro}</span>
      </div>
      {formSubmitted ? (
        <div className={styles.webformThankyou}>
          <div>
            <CheckmarkSvg viewBox="0 0 16 16" />
          </div>
          <span className={styles.webformThankyou}>
            Thank you for subscribing!
          </span>
        </div>
      ) : (
        <div className={styles.webformForm}>
          <Form onSubmit={handleSubmit} onFormValidate={handleValidation}>
            <TextField
              id="email"
              label="Email Address"
              defaultValue={accountContext.state?.email}
              errorMessage={formErrors.email}
              automationId="webform-email-field"
            />

            <Button
              className={`gtm-email-subscribe ${
                submittingForm ? 'submitting' : ''
              }`}
              primaryColor={buttonColors && convertButtonColor(buttonColors)}
              buttonStyle={
                buttonColors &&
                convertButtonColor(buttonColors) === Colors.primary.white
                  ? 'outlined'
                  : null
              }
              onDarkBg={buttonColors && reverseColors(buttonColors)}
              fillContainer={false}
              automationId="webform-subscribe-button"
            >
              <span className="webform-button">
                {webFormData.firstFormButtonText}
              </span>
              <LoadingIndicator
                color={Colors.primary.white.standard}
                width="24"
                height="24"
              />
            </Button>
          </Form>
          {webFormData?.disclaimer && (
            <p className={styles.disclaimer}>{webFormData?.disclaimer}</p>
          )}
        </div>
      )}
    </div>
  );
};

WebForm.propTypes = {
  webFormData: PropTypes.shape({
    formHeader: PropTypes.string,
    formIntro: PropTypes.string,
    disclaimer: PropTypes.string,
    firstFormButtonText: PropTypes.string,
    buttonAndTextColors: PropTypes.string,
    originWebFormType: PropTypes.string,
    originCampaign: PropTypes.string,
    originWebFormCategory: PropTypes.string,
    originWebPageType: PropTypes.string,
    originWebApp: PropTypes.string,
    dataExtension: PropTypes.string,
    formActionType: PropTypes.string,
    formName: PropTypes.string,
    formMethod: PropTypes.string,
    formID: PropTypes.string,
    modelNumber: PropTypes.string,
    hcNumber: PropTypes.string,
  }),
};

export default WebForm;
